<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#3F033B"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showsnackbar" color="#3F033B" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: white">
            {{ msg }}
          </span>
        </v-flex>

        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #000">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex xs12>
        <v-layout wrap>
          <v-flex xs12 sm10 text-left>
            <span class="mainHeader">OLD GOLD</span>
          </v-flex>
          <v-flex xs12 sm2 text-right>
            <v-text-field
              background-color="white"
              v-model="keyword"
              color="#b234a9"
              clearable
              hide-details
              placeholder="Search"
              outlined
              class="rounded-xl"
              dense
              append-icon="mdi-magnify"
            >
            </v-text-field>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 py-6 text-left>
        <v-card
          tile
          elevation="0"
          class="rounded-lg"
          :color="$route.name == 'Dashboard' ? 'transparent' : '#FFFFFF'"
          :flat="$route.name == 'Dashboard' ? true : false"
        >
          <v-layout wrap pa-4>
            <v-flex
              xs12
              pb-3
              pb-md-0
              style="font-family: DmSansBold; font-size: 25px"
              >ACIDING</v-flex
            >
          </v-layout>
          <v-layout wrap>
            <v-flex xs10 sm3 md3 pl-5 px-2 pb-3 pb-md-0>
              <v-flex xs12 pb-1 class="subhead3">Date :</v-flex>
              <v-menu
                ref="menu2"
                v-model="menu2"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date"
                    placeholder="Date"
                    outlined
                    color="#b234a9"
                    hide-details=""
                    dense
                    append-icon="mdi-calendar-multiselect"
                    v-bind="attrs"
                    v-on="on"
                    clearable
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date"
                  color="#571964"
                  no-title
                  @input="menu2 = false"
                ></v-date-picker>
              </v-menu>
            </v-flex>
            <v-flex xs12 sm9 md9 text-right pr-4 align-self-baseline>
              <v-btn dark color="#3F053C" @click="$router.push('/AcidingAfter')">acidified gold</v-btn>
            </v-flex>
          </v-layout>

          <v-layout wrap py-2>
            <v-flex xs12 text-left>
              <v-card
                class="rounded-lg"
                elevation="0"
                :color="$route.name == 'Dashboard' ? 'transparent' : '#FFFFFF'"
                :flat="$route.name == 'Dashboard' ? true : false"
              >
                <v-layout wrap pa-4>
                  <v-flex xs12>
                    <v-simple-table style="border-bottom: 0.5px solid #e6e6e6 !important
                    ;">
                      <thead>
                        <tr>
                          <th class="tablefont tble">
                            <b>S.No.</b>
                          </th>
                          <th class="tablefont tble">
                            <b>Bill No</b>
                          </th>
                          <th class="tablefont tble">
                            <b>Item Description</b>
                          </th>
                          <th class="tablefont tble">
                            <b>HSN Code</b>
                          </th>
                          <th class="tablefont tble">
                            <b>gold rate</b>
                          </th>
                          <th class="tablefont tble">
                            <b>gross weight</b>
                          </th>
                          <th class="tablefont tble">
                            <b>net weight </b>
                          </th>
                          <th class="tablefont tble">
                            <b>total amount</b>
                          </th>

                          <th class="tablefont tble">
                            <b>Actions</b>
                          </th>
                        </tr>
                      </thead>
                      <tbody  class="bordered-table">
                        <tr>
                          <td>
                            <span>s</span>
                          </td>
                          <td>
                            <span>s</span>
                          </td>
                          <td>
                            <span>s</span>
                          </td>
                          <td>
                            <span>s</span>
                          </td>
                          <td>
                            <span>s</span>
                          </td>
                          <td>
                            <span>s</span>
                          </td>
                          <td>
                            <span>s</span>
                          </td>
                          <td>
                            <span>s</span>
                          </td>
                          <td>
                            <v-container fluid>
                              <v-checkbox
                                v-model="checkbox"
                                      color="#3F053C"
                                value="red"
                                hide-details
                              ></v-checkbox>
                            </v-container>
                          </td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                  </v-flex>
                </v-layout>
                <v-layout wrap align-center justify-end class="px-2">
                  <v-flex xs6 sm2 md2 pl-0 pl-lg-3 py-2 class="subhead3">
                    Total weight(gm):
                  </v-flex>
                  <v-flex xs6 sm2 md2 pr-0 pr-lg-2 py-2>
                    <v-text-field
                      outlined
                      dense
                      color="#b234a9"
                      hide-details=""
                      disabled
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout>
                  <v-flex xs12 py-4 text-right pr-4 align-self-baseline>
                    <v-btn dark color="#3F053C" min-width="140px" @click="$router.push('/AcidingConfirm')">save</v-btn>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
// import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showsnackbar: false,
      timeout: 2000,
      msg: null,
      username: null,
      keyword: null,
      name: null,
      address: null,
      phone: null,
      email: null,
      showField1: false,
      showField2: false,
      showField3: false,
      showField4: false,
      row: null,
      date: null,
      menu2: false,
      checkbox: true,
    };
  },
  methods: {
    validateInput() {
      if (!this.email) {
        this.msg = "Please provide email";
        this.showsnackbar = true;
        return;
      } else if (!this.name) {
        this.msg = "Please provide name";
        this.showsnackbar = true;
        return;
      } else if (!this.phone) {
        this.msg = "Please provide phone";
        this.showsnackbar = true;
        return;
      } else if (!this.username) {
        this.msg = "Please provide username";
        this.showsnackbar = true;
        return;
      } else if (!this.address) {
        this.msg = "Please provide address";
        this.showsnackbar = true;
        return;
      } else {
        this.stocklist();
      }
    },
    toggleFields() {
      this.showField1 = !this.showField1;
      this.showField2 = !this.showField2;
      this.showField3 = !this.showField3;
      this.showField4 = !this.showField4;
    },
    // stocklist() {
    //   axios({
    //     url: "/stock/getalllist",
    //     method: "get",
    //     data: {
    //       username: this.username,
    //       email: this.email,
    //       jewelleryName: this.name,
    //       phone: this.phone,
    //       location: this.address,
    //     },
    //     headers: {
    //       "x-auth-token": localStorage.getItem("token"),
    //     },
    //     params: {
    //       page: this.currentpage,
    //       limit: this.limit,
    //       from: this.from,
    //       to: this.to,
    //     },
    //     // params: {
    //     //   "x-auth-token": localStorage.getItem("token"),
    //     // },
    //   })
    //     .then((response) => {
    //       this.appLoading = false;
    //       if (response.data.status == true) {
    //         this.array = response.data.data;
    //         this.pages = Math.ceil(response.data.totalLength / this.limit);
    //       } else {
    //         this.msg = response.data.msg;
    //         this.showsnackbar = true;
    //       }
    //     })
    //     .catch((err) => {
    //       this.appLoading = false;
    //       this.ServerError = true;
    //       console.log(err);
    //     });
    // },
  },
};
</script>
